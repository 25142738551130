<template>
  <router-view />
</template>

<script>
export default {
  mounted() {
    // window.addEventListener("message", (e) => {
    //   if (e.origin.includes("schtest.fjrh.cn")) {
    //     localStorage.setItem("Token", e.data.Token);
    //     localStorage.setItem("OrgToken", e.data.OrgToken);
    //     localStorage.setItem("UserInfo", e.data.UserInfo);
    //     localStorage.setItem("Path", e.data.path);
    //   }
    // });
    // if (localStorage.getItem("Path")) {
    //   this.$router.push(`/${localStorage.getItem("Path")}`);
    // }
    if (location.hash) {
      const info = location.hash.split("#")[1];
      localStorage.setItem("Token", info.split(",")[0]);
      localStorage.setItem("OrgToken", info.split(",")[1]);
      localStorage.setItem("UserInfo", info.split(",")[2]);
      localStorage.setItem("Path", info.split(",")[3]);
      this.$nextTick(() => {
        if (localStorage.getItem("Path")) {
          this.$router.push(`/${localStorage.getItem("Path")}`);
        }
      });
    }
  },
  // beforeUnmount() {
  //   window.removeEventListener("message", () => null);
  // },
};
</script>

<style lang="less">
body {
  background-color: #f3f4f8;
  height: 100vh;
  #app {
    height: 100%;
  }
}
* {
  -webkit-touch-callout: none; /*系统默认菜单被禁用*/
  -webkit-user-select: none; /*webkit浏览器*/
  -khtml-user-select: none; /*早起浏览器*/
  -moz-user-select: none; /*火狐浏览器*/
  -ms-user-select: none; /*IE浏览器*/
  user-select: none; /*用户是否能够选中文本*/
}
</style>
