import { h, resolveComponent } from "vue";
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
// import emptyLayout from "../App.vue"
import Home from '../views/Home.vue'

const emptyLayout = { render: () => h(resolveComponent("router-view")) };

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: "/login",
    name: "Login",
    component: () => import(/* webpackChunkName: "login" */ "../views/login/index.vue")
  },
  // 课表相关
  {
    path: "/schedule",
    component: emptyLayout,
    name: "schedule",
    redirect: "/schedule/my",
    children: [
      {
        path: "my",
        name: "myschedule",
        component: () => import(/* webpackChunkName: "schedule" */ "../views/schedule/my.vue")
      },
      {
        path: "today",
        name: "todayschedule",
        component: () => import(/* webpackChunkName: "schedule" */ "../views/schedule/today.vue")
      }
    ]
  },
  // 督导评价
  {
    path: '/supervision',
    name: 'Supervision',
    redirect: '/supervision/list',
    component: emptyLayout,
    children: [
      {
        path: 'list',
        name: "SupervisionList",
        component: () => import(/* webpackChunkName: "supervision" */ "../views/supervision/index.vue")
      },
      {
        path: "wait-evaluated-detail",
        name: "WaitEvaluatedDetail",
        component: () => import(/* webpackChunkName: "supervision" */ "../views/supervision/waitEvaluated.vue")
      },
      {
        path: "already-evaluated-detail",
        name: "AlreadyEvaluatedDetail",
        component: () => import(/* webpackChunkName: "supervision" */ "../views/supervision/alreadyEvaluatedDetail.vue")
      },
      {
        path: "be-evaluated-detail",
        name: "BeEvaluatedDetail",
        component: () => import(/* webpackChunkName: "supervision" */ "../views/supervision/beEvaluatedDetail.vue")
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path === "/" || to.path === "/login") {
    next();
  } else {
    const userInfo = localStorage.getItem("UserInfo");
    if (userInfo) {
      next();
    } else {
      next({ path: '/login' });
    }
  }
})

export default router
