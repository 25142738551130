import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Vant from 'vant'
import 'vant/lib/index.css'

// 字体适配
import "amfe-flexible/index.js";

import "./assets/icons/iconfont.css";
import "./assets/styles/index.less";
import HeadTop from "./components/HeadTop.vue";

createApp(App).use(store).use(router).use(Vant).component("head-top", HeadTop).mount('#app')
