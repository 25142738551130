
import { Decrypt } from "../scripts/utils";
import { Icon, Toast } from "vant";

export default {
  components: {
    [Icon.name]: Icon,
  },
  data() {
    return {
      userName: localStorage.getItem("UserInfo")
        ? JSON.parse(Decrypt(localStorage.getItem("UserInfo"))).UserName
        : "未登录",
    };
  },
  methods: {
    developing() {
      Toast.fail("该模块正在开发中");
    },
  },
};
